import React, { useState, useEffect, useRef } from 'react'
import Draggable from 'react-draggable';
import useSound from 'use-sound';

// components
import Screener from '../Screener'
import QuoteBox from '../QuoteBox'
import TradingViewChart from '../TradingViewChart'

// controllers
import useQuote from '../../controllers/useQuote'
import useNews from '../../controllers/useNews'
import useTradingViewChart from '../../controllers/useTradingViewChart'

function DayTrade() {

    const [changeTable, setChangeTable] = useState({ colorColumns: ['change'], socketChannel: 'gapper', tableName: "gapperHigh" })
    const [haltTable, setHaltTable] = useState({ colorColumns: ['change'], tableType: 'halt', socketChannel: 'halt', tableName: "halt" })
    const [changeTable5m3c, setChangeTable5m3c] = useState({ interval: 5, change: 3, colorColumns: ['change5m'], socketChannel: '5m3c', tableName: "momo5m3c" })

    const [selectedTicker, setSelectedTicker] = useState("")

    const [ticker, setTicker] = useState("AAPL")
    let quoteInfo = useQuote(ticker, selectedTicker)

    let chartIdName = 'tradeViewChart'
    let chartInfo = useTradingViewChart(ticker, 0, chartIdName, 'livechart', 1)
    useTradingViewChart(ticker, 1, `${chartIdName}2`, 'livechart', 0)
    useTradingViewChart(ticker, 2, `${chartIdName}3`, 'livechart', 0)

    let newsInfo = useNews()

    useEffect(() => {
        if (selectedTicker) {
            setTicker(selectedTicker)
        }
        // setSelectedTicker("FB")
    }, [selectedTicker])
    // const [changeTable10m5c, setChangeTable10m5c] = useState({ interval: 10, change: 5, colorColumns: ['change10m'], socketChannel: 'gapper' })
    // const [changeTable1m3c, setChangeTable1m3c] = useState({ interval: 1, change: 5, colorColumns: ['change1m'], socketChannel: '1m5c' })


    function updateIndex(e) {
        let dragHandles = document.getElementsByClassName("dragHandle");
        for (let eachHandle of dragHandles) {
            if (eachHandle) {
                let el = eachHandle.closest(".react-draggable");
                if (el) {
                    el.style.zIndex = 1
                }
            }
        }
        let el = e.target.closest(".react-draggable");
        if (el) {
            el.style.zIndex = 90
        }
    }
    const nodeRef = useRef(null);

    return (
        <div className="container-fluid">
            <div className="row">
                <Draggable
                    handle=".dragHandle"
                    scale={1}
                    defaultPosition={{ x: 0, y: 0 }}
                    position={null}
                    onStart={updateIndex}
                    // onMouseDown={updateIndex}
                    nodeRef={nodeRef}>
                    <div className="col-md-12 col-lg-6 col-xl-6 col-xxl-4"
                        onClick={updateIndex}
                        ref={nodeRef}>
                        <Screener title="Momentum stocks"
                            description="Stock price increase of at least 3% in last 5 minutes, you'll see star next to stock name if there is a news. Click on gear icon to change the settings"
                            interval={changeTable5m3c.interval}
                            change={changeTable5m3c.change}
                            colorColumns={changeTable5m3c.colorColumns}
                            onChange={setChangeTable5m3c}
                            socketChannel={changeTable5m3c.socketChannel}
                            tableName={changeTable5m3c.tableName}
                            setOnClick={setSelectedTicker}
                            playSound={changeTable5m3c.playSound} />
                    </div>
                </Draggable>

                <Draggable
                    handle=".dragHandle"
                    scale={1}
                    defaultPosition={{ x: 0, y: 0 }}
                    position={null}
                    onStart={updateIndex}
                    nodeRef={nodeRef}>
                    <div className="col-md-12 col-lg-6 col-xl-6 col-xxl-4"
                        onClick={updateIndex}
                        ref={nodeRef}>
                        <TradingViewChart
                            idName={chartIdName}
                            title="Chart"
                            ticker={ticker}
                            height="435"
                        />
                    </div>
                </Draggable>

                <Draggable
                    handle=".dragHandle"
                    scale={1}
                    defaultPosition={{ x: 0, y: 0 }}
                    position={null}
                    onStart={updateIndex}
                    nodeRef={nodeRef}>
                    <div className="col-md-12 col-lg-6 col-xl-6 col-xxl-4"
                        onClick={updateIndex}
                        ref={nodeRef}>
                        <TradingViewChart
                            idName={`${chartIdName}2`}
                            title="Chart"
                            ticker={ticker}
                            height="435"
                        />
                    </div>
                </Draggable>

                <Draggable
                    handle=".dragHandle"
                    scale={1}
                    defaultPosition={{ x: 0, y: 0 }}
                    position={null}
                    onStart={updateIndex}
                    // onMouseDown={updateIndex}
                    nodeRef={nodeRef}>
                    <div className="col-md-12 col-lg-6 col-xl-6 col-xxl-4"
                        onClick={updateIndex}
                        ref={nodeRef}>
                        <Screener title="High of the day"
                            description="Highest price increase in one day (change %)"
                            colorColumns={changeTable.colorColumns}
                            onChange={setChangeTable}
                            socketChannel={changeTable.socketChannel}
                            tableName={changeTable.tableName}
                            setOnClick={setSelectedTicker} />
                    </div>
                </Draggable>

                <Draggable
                    handle=".dragHandle"
                    scale={1}
                    defaultPosition={{ x: 0, y: 0 }}
                    position={null}
                    onStart={updateIndex}
                    nodeRef={nodeRef}>
                    <div className="col-md-12 col-lg-6 col-xl-6 col-xxl-3"
                        onClick={updateIndex}
                        ref={nodeRef}>
                        <QuoteBox ticker={ticker}
                            setQuote={setTicker}
                            quoteInfo={quoteInfo}
                            setSelectedTicker={setSelectedTicker}
                            isFooterRequired={false}
                        />
                        {/* <div className="well">Momo Scanners - 1 minutes - 5 percent</div>
                    <Screener interval={changeTable1m3c.interval} change={changeTable1m3c.change} colorColumns={changeTable1m3c.colorColumns} onChange={setChangeTable1m3c} socketChannel={changeTable1m3c.socketChannel} /> */}
                    </div>
                </Draggable>

                <Draggable
                    handle=".dragHandle"
                    scale={1}
                    defaultPosition={{ x: 0, y: 0 }}
                    position={null}
                    onStart={updateIndex}
                    nodeRef={nodeRef}>
                    <div className="col-md-12 col-lg-6 col-xl-6 col-xxl-5"
                        onClick={updateIndex}
                        ref={nodeRef}>
                        <TradingViewChart
                            idName={`${chartIdName}3`}
                            title="Chart"
                            ticker={ticker}
                            height="435"
                        />
                    </div>
                </Draggable>

                <Draggable
                    handle=".dragHandle"
                    scale={1}
                    defaultPosition={{ x: 0, y: 0 }}
                    position={null}
                    onStart={updateIndex}
                    // onMouseDown={updateIndex}
                    nodeRef={nodeRef}>
                    <div className="col-md-12 col-lg-6 col-xl-6 col-xxl-4"
                        onClick={updateIndex}
                        ref={nodeRef}>
                        <Screener title="Halted"
                            description="Halted stock information"
                            colorColumns={haltTable.colorColumns}
                            tableType={haltTable.tableType}
                            onChange={setHaltTable}
                            socketChannel={haltTable.socketChannel}
                            tableName={haltTable.tableName}
                            setOnClick={setSelectedTicker}
                            playSound={haltTable.playSound} />
                    </div>
                </Draggable>
            </div>
        </div>
    )
}

export default DayTrade
