import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async'
import './index.css';
import ReactGA from "react-ga4"

import { GlobalStateContextProvider } from './controllers/useContextReducer'

import App from './App';
import ChartsRoute from './components/charts/ChartsRoute'
import TradesRoute from './components/trades/TradesRoute'
import DayTradeRoute from './components/daytrade/DayTradeRoute'
import Home from './components/home/Home'
import reportWebVitals from './reportWebVitals';

ReactGA.initialize("G-N39VK1YJRP",
  {
    siteSpeedSampleRate: 100
  }
)
ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
const helmetContext = {};

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <HelmetProvider context={helmetContext}>
    <BrowserRouter>
      {/* <React.StrictMode> */}
      <GlobalStateContextProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/scanner" element={<App />} />
          <Route path="/daytrade" element={<DayTradeRoute />} />
          <Route path="/charts" element={<ChartsRoute />} />
          <Route path="/trades" element={<TradesRoute />} />
        </Routes>
      </GlobalStateContextProvider>
      {/* </React.StrictMode> */}
    </BrowserRouter>
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
